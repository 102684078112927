const HeaderModules = [
    {
        name: 'home',
        link: '/home'
    },
    {
        name: 'allCourses',
        link: 'all-courses'
    },
    {
        name: 'reportCard',
        link: 'report-card'
    },
    {
        name: 'allCertificates',
        link: 'all-certificates'
    },
    {
        name: 'achievements',
        link: 'achievements'
    }
    // {
    //     name: 'Invoice',
    //     link: 'invoice'
    // }
    // {
    //     name: 'Leaderboard',
    //     link: 'leaderboard'
    // },
    // {
    //     name: 'Reports',
    //     link: 'reports'
    // },
    // {
    //     name: 'Feedback',
    //     link: 'feedback'
    // },
    // {
    //     name: 'FAQ',
    //     link: 'faq'
    // }
];

export default HeaderModules;
