export const SERVICE_NAME = process.env.REACT_APP_SERVICE_NAME || 'data_service.php?service';
export const MOODLE_SERVICE = process.env.REACT_APP_MOODLE_SERVICE || 'moodle_mobile_app';
export const BASEURL = process.env.REACT_APP_BASE_URL || 'http://localhost/local/shn_api';
export const MAINURL = process.env.REACT_APP_BASE_URL || 'http://localhost/local/shn_api';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost/shn-elearning';
export const captChaSecretKey = process.env.REACT_APP_CAPTCHA_SECRET_KEY || '';
export const captChaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';
export const lanApiUrl = process.env.REACT_APP_LAN_API_URL || 'http://localhost/lan-api';
export const shnUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost/shn-elearning';
export const localApiBaseUrl = process.env.REACT_APP_LOCAL_API_URL || 'http://localhost/shn-web/local/shn_api';
export const devApiBaseUrl = process.env.REACT_APP_DEV_API_URL || 'http://localhost/shn-elearning/local/shn_api';
export const qaApiBaseUrl = process.env.REACT_APP_QA_API_URL || 'http://localhost/qa-api';
export const chorchaApiBaseUrl = process.env.REACT_APP_CHORCHA_API_URL || 'http://localhost/chorcha-api';
export const headerColorCode = process.env.REACT_APP_HEADER_COLOR_CODE || '#00B0F0';

export type PostDataType = {
    data: {
        type: string;
        attributes: any;
    };
};
