import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Box } from '@mui/material';
import { LanguageOutlined } from '@mui/icons-material';

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LanguageOutlined sx={{ mr: 1 }} />
            <ButtonGroup variant="outlined" size="small">
                <Button
                    onClick={() => changeLanguage('en')}
                    variant={i18n.language === 'en' ? 'contained' : 'outlined'}
                    sx={{
                        bgcolor: i18n.language === 'en' ? '#81c784' : '#a5d6a7',
                        color: i18n.language === 'en' ? '#fff' : '#2e7d32',
                        borderColor: '#81c784',
                        '&:hover': {
                            bgcolor: '#66bb6a',
                            borderColor: '#66bb6a',
                            color: '#fff'
                        }
                    }}
                >
                    EN
                </Button>
                <Button
                    onClick={() => changeLanguage('bn')}
                    variant={i18n.language === 'bn' ? 'contained' : 'outlined'}
                    sx={{
                        bgcolor: i18n.language === 'bn' ? '#81c784' : '#a5d6a7',
                        color: i18n.language === 'bn' ? '#fff' : '#2e7d32',
                        borderColor: '#81c784',
                        '&:hover': {
                            bgcolor: '#66bb6a',
                            borderColor: '#66bb6a',
                            color: '#fff'
                        }
                    }}
                >
                    বাং
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default LanguageSwitcher;
