import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { MAINURL, BASEURL, SERVICE_NAME } from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme: any) => ({
    root: {
        position: 'relative',
        paddingBottom: '100px' // Adjust padding at the bottom to make space for the footer
    },
    content: {
        // Add your content styles here
    },
    footer: {
        padding: theme.spacing(1),
        backgroundColor: 'black',
        color: theme.palette.common.white,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: theme.zIndex.drawer + 1
    }
}));

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            await axios
                .get(`${MAINURL}/user/${SERVICE_NAME}=logout`, {
                    headers: {
                        Authorization: serviceToken
                    }
                })
                .then((response) => {})
                .catch((error) => {});
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>{/* Your main content goes here */}</div>
            <footer className={classes.footer}>
                <Grid container justifyContent="space-between" alignItems="center" paddingX={2}>
                    <Grid item>
                        <Typography variant="body2" color="white">
                            © {t(String(new Date().getFullYear()))} {t('copyright')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button color="inherit" onClick={handleLogout}>
                            {t('logout')}
                        </Button>
                    </Grid>
                </Grid>
            </footer>
        </div>
    );
};

export default Footer;
