import { lazy } from 'react';
// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
// import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthHrisLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/HrisLogin')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthAccountVerification = Loadable(lazy(() => import('views/pages/authentication/auth-forms/AuthAccountVerification')));

const AuthVerification = Loadable(lazy(() => import('views/pages/authentication/authentication3/CodeVerification')));
// const AuthRegister = Loadable(lazy(() => import('views/pages/authentication')));
const AuthForgotPin = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPin')));
const AuthResetPin = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPin')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const Home = Loadable(lazy(() => import('views/pages/Home')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/hris-login',
            element: <AuthHrisLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/verification',
            element: <AuthVerification />
        },
        {
            path: '/forgot-pin',
            element: <AuthForgotPin />
        },
        {
            path: '/reset-pin',
            element: <AuthResetPin />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset',
            element: <AuthResetPassword />
        },
        {
            path: '/verify-user',
            element: <AuthAccountVerification />
        }
    ]
};

export default LoginRoutes;
